<template>
  <div class="card p-shadow-6">
    <h1>Surveys Concluídos</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog />

    <Divider />

    <div class="p-field p-grid">
      <div class="p-col-12 p-md-5 p-mt-1">
        <label for="year-selector">Ano</label>
        <InputNumber
          id="year-selector"
          name="year-selector"
          :value="year"
          class="p-col-10"
          showButtons
          buttonLayout="horizontal"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          :step="1"
          :max="currentYear"
          :min="minYear"
          :useGrouping="false"
          @input="yearChanged"
        />
      </div>
      <div class="p-col-12 p-md-2 p-mt-1">
        <Button
          label="Todos os Anos"
          style="width: 100%"
          class="p-button-outlined"
          @click="getPats"
        />
      </div>
    </div>

    <DataTable
      scrollHeight="60vh"
      :value="patsList"
      :filters.sync="filters"
      filterDisplay="row"
      :loading="loading"
      :paginator="true"
      :rows="patsList.length"
      class="p-datatable-sm p-datatable-responsive"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="Mostrar {first} ao {last} de {totalRecords} surveys"
      sortField="id"
      :sortOrder="1"
      responsiveLayout="scroll"
    >
      <template #empty> Nada a mostrar. </template>
      <template #loading> A carregar. Por favor Aguarde... </template>

      <Column field="id" header="Pat" sortable filterField="id">
        <template #body="slotProps">
          {{ slotProps.data.id }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="name" header="Nome" sortable filterField="name">
        <template #body="slotProps">
          {{ slotProps.data.name }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="resume" header="Resumo" filterField="resume">
        <template #body="slotProps">
          {{ slotProps.data.resume }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="seller" header="Vendedor" sortable filterField="seller">
        <template #body="slotProps">
          {{ slotProps.data.seller }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="technician"
        header="Téc. Resp. Survey"
        sortable
        filterField="technician"
      >
        <template #body="slotProps">
          {{ slotProps.data.technician }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="date_date"
        header="Survey Data"
        sortable
        filterField="date_date"
        dataType="date"
      >
        <template #body="slotProps">
          {{ slotProps.data.date }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <v-date-picker
            v-model="filterModel.value"
            @input="filterCallback()"
            :masks="{
              input: 'YYYY-MM-DD',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="p-column-filter p-inputtext p-component"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </template>
      </Column>

      <Column :headerStyle="{ width: '100px' }">
        <template #body="slotProps">
          <Button
            v-tooltip.left="'Ver informação do Pat'"
            icon="pi pi-eye"
            class="p-button-rounded p-button-primary p-button-outlined p-mr-2"
            @click="viewPatInfo(slotProps.data.id)"
          />
          <Button
            v-if="slotProps.data.to_do == 'File'"
            v-tooltip.left="'Transferir ficheiro'"
            icon="pi pi-download"
            class="p-button-rounded p-button-primary p-button-outlined p-mr-2"
            @click="downloadFile(slotProps.data)"
          />
          <Button
            v-if="slotProps.data.solution != ''"
            v-tooltip.left="'Ver solução'"
            icon="pi pi-comment"
            class="p-button-rounded p-button-success p-button-outlined p-mr-2"
            @click="viewSolution(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>

    <Dialog
      :visible.sync="showForm"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Solução'"
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
    >
      <div>
        <p><b>Nome:</b> {{ solutionInfo.name }}</p>
        <p><b>Vendedor:</b> {{ solutionInfo.seller }}</p>
        <p><b>Técnico:</b> {{ solutionInfo.technician }}</p>
        <p v-if="solutionInfo.resume">
          <b>Resumo:</b> {{ solutionInfo.resume }}
        </p>
        <p><b>Solução:</b> {{ solutionInfo.solution }}</p>
      </div>
      <template #footer>
        <Button
          label="Fechar"
          icon="pi pi-times"
          class="p-button-text p-button-danger"
          @click="closeForm"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import patsService from "../services/pats.service";
import filesService from "../services/files.service";
import { FilterMatchMode } from "primevue/api/";

export default {
  name: "Miss",
  data() {
    return {
      loading: true,
      patsList: [],
      showForm: false,
      solutionInfo: {},
      year: new Date().getFullYear(),
      currentYear: new Date().getFullYear(),
      minYear: 2020,
      filters: {
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        date_date: { value: null, matchMode: FilterMatchMode.DATE_IS },
        resume: { value: null, matchMode: FilterMatchMode.CONTAINS },
        seller: { value: null, matchMode: FilterMatchMode.CONTAINS },
        technician: { value: null, matchMode: FilterMatchMode.CONTAINS },
        approved: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
    };
  },
  created() {
    this.loading = true;
    this.getPatsByYear();
  },
  methods: {
    getPats() {
      this.loading = true;
      this.year = null;
      let userId = this.$store.state.auth.user.id;
      return patsService.getPatsClosedList(userId).then((response) => {
        response = response.map((v) => ({ ...v, date_date: new Date(v.date) }));
        this.loading = false;
        return (this.patsList = response);
      });
    },
    getPatsByYear() {
      this.loading = true;
      let userId = this.$store.state.auth.user.id;
      return patsService
        .getPatsClosedListByYear(userId, this.year)
        .then((response) => {
          response = response.map((v) => ({
            ...v,
            date_date: new Date(v.date),
          }));
          this.loading = false;
          return (this.patsList = response);
        });
    },
    closeForm() {
      this.showForm = false;
      this.solutionInfo = {};
    },
    viewSolution(info) {
      this.solutionInfo = info;
      this.showForm = true;
    },
    yearChanged(year) {
      if (year < 2020 || year > this.currentYear || year == this.year) {
        return;
      }
      this.year = year;
      return this.getPatsByYear();
    },
    downloadFile(info) {
      let data = {
        origin_reference: info.reference,
      };
      filesService.getFileFromOriginReference(data).then((response) => {
        if (!response || response.length == 0) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao fazero download",
            life: 3000,
          });
        }
        var downloadElement = document.createElement("a");

        if (response[0].type !== undefined && response[0].type == "pdf") {
          downloadElement.href = `data:application/${response[0].type};base64,${response[0].src}`;
        } else if (response[0].type !== undefined) {
          downloadElement.href = `data:image/${response[0].type};base64,${response[0].src}`;
        }
        let fileName = info.name
          .replace(/\w+/g, function (txt) {
            // uppercase first letter and add rest unchanged
            return txt.charAt(0).toUpperCase() + txt.substr(1);
          })
          .replace(/\s/g, "");
        downloadElement.download = `${info.id}-${fileName}.${response[0].type}`;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        downloadElement.remove();
      });
    },
    viewPatInfo(pat) {
      let route = this.$router.resolve({
        path: `/pat-details/${pat}`,
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>
<style scoped lang="scss">
#year-selector {
  width: 60px;
}

.p-column-filter {
  width: 100%;
}
</style>
